import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { generate, GeneratedOutput } from '../thunks/generate'
import { OutputType } from '../../lib/outputs'

type GeneratorType = {
  headers: {
    source: string
    destination: string
  },
  outputType: OutputType
  output?: GeneratedOutput
  outputOptions: Record<string, unknown> & {
    permanent: boolean
  }
}

export const initialState: GeneratorType = {
  headers: {
    source: '',
    destination: '',
  },
  outputType: 'json',
  outputOptions: {
    permanent: false,
  },
}

const { actions, reducer } = createSlice({
  name: 'generator',
  initialState,
  reducers: {
    setHeader: (state, action: PayloadAction<{ header: 'source' | 'destination', value: string }>) => {
      state.headers[action.payload.header] = action.payload.value
      state.output = undefined
    },
    setOutputType: (state, action: PayloadAction<OutputType>) => {
      state.outputType = action.payload
      state.output = undefined
    },
    setOutputOption: (state, action: PayloadAction<{ option: string, value: unknown }>) => {
      state.outputOptions[action.payload.option] = action.payload.value
      state.output = undefined
    },
  },
  extraReducers: builder => {
    builder.addCase(generate.fulfilled, (state, action) => {
      state.output = action.payload
    })
  }
})

export const generator = reducer

export const { setHeader, setOutputType, setOutputOption } = actions