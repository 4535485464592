import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { outputs, OutputType } from '../../lib/outputs'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import React, { ChangeEvent, useCallback } from 'react'
import { setOutputOption, setOutputType } from '../../store/slices/generator'

export const OutputSelector: React.VFC = () => {
  const type = useAppSelector(state => state.generator.outputType)
  const isPermanent = useAppSelector(state => state.generator.outputOptions.permanent)
  const dispatch = useAppDispatch()

  const dispatchOutputType = useCallback((event: SelectChangeEvent<OutputType>) => {
    dispatch(setOutputType(event.target.value as OutputType))
  }, [dispatch])

  const dispatchPermanence = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setOutputOption({ option: 'permanent', value: event.target.checked }))
  }, [dispatch])

  return (
    <Grid container gap={2}>
      <Grid item xs>
        <FormControl fullWidth>
          <InputLabel id="output-selector">
            Output
          </InputLabel>
          <Select label="Output" value={type} onChange={dispatchOutputType} labelId="output-selector" variant={'outlined'}>
            {Object.keys(outputs).map((key) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs>
        <FormControl fullWidth>
          <FormControlLabel control={< Checkbox value={isPermanent} onChange={dispatchPermanence} />} label={"Permanent Redirect"} />
        </FormControl>
      </Grid>
    </Grid>
  )
}